// src/pages/Payment.js

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  Grid,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
} from '@mui/material';
import { sendPaymentData } from '../api/paymentApi'; // Ödeme API'sine gönderim fonksiyonu

export default function Payment() {
  const location = useLocation();
  const navigate = useNavigate();
  const { cart, type } = location.state || {};

  const [buyer, setBuyer] = useState({
    id: '',
    name: '',
    surname: '',
    identityNumber: '', // TC Kimlik Numarası
    email: '',
    gsmNumber: '',
    registrationDate: '',
    lastLoginDate: '',
    registrationAddress: '',
    city: '',
    country: '',
    zipCode: '',
    ip: '',
  });

  const [shippingAddress, setShippingAddress] = useState({
    address: '',
    zipCode: '',
    contactName: '',
    city: '',
    country: '',
  });

  const [billingAddress, setBillingAddress] = useState({
    address: '',
    zipCode: '',
    contactName: '',
    city: '',
    country: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  if (!cart || cart.length === 0) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Alert severity="error">Sepet bilgisi bulunamadı veya sepetiniz boş.</Alert>
      </Container>
    );
  }

  const handleChange = (e, setFunction) => {
    const { name, value } = e.target;
    setFunction((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basit doğrulama
    if (!buyer.email || !buyer.name || !buyer.surname || !buyer.identityNumber) {
        setError('Lütfen tüm gerekli alanları doldurun.');
        return;
    }

    const paymentData = {
        buyer,
        shippingAddress: type === 'onetime' ? shippingAddress : undefined, // Abonelikse gönderim adresi gönderilmez
        billingAddress,
        items: cart,
        paymentType: type, // 'subscription' veya 'onetime'
    };

    try {
        const response = await sendPaymentData(paymentData);

        if (response.status === 'success') {
            if (type === 'onetime') {
                // Iyzico ödeme sayfasına yönlendirme
                window.location.href = response.paymentPageUrl;
            } else if (type === 'subscription' && response.checkoutFormContent) {
                // Subscription için checkout formunu DOM'a ekleyerek göster
                const container = document.createElement('div');
                container.innerHTML = response.checkoutFormContent;
                document.body.appendChild(container);
            }
        } else {
            setError('Ödeme sırasında bir hata oluştu.');
        }
    } catch (err) {
        setError(err.message || 'Ödeme sırasında bir hata oluştu.');
    }
};


  const calculateTotalPrice = () => {
    return cart
      .reduce((total, item) => {
        const price = Array.isArray(item.prices)
          ? item.prices.find((p) => p.currencyType === 'try')?.value || 0
          : item.prices?.value || 0;
        return total + price * (item.quantity || 1);
      }, 0)
      .toFixed(2);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" fontWeight="bold" mb={3}>
        {type === 'subscription' ? 'Abonelik' : 'Tek Seferlik'} Ödeme Bilgileri
      </Typography>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

      <form onSubmit={handleSubmit}>
        <Typography variant="h6" gutterBottom>
          Alıcı Bilgileri
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Ad"
              name="name"
              fullWidth
              value={buyer.name}
              onChange={(e) => handleChange(e, setBuyer)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Soyad"
              name="surname"
              fullWidth
              value={buyer.surname}
              onChange={(e) => handleChange(e, setBuyer)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="TC Kimlik Numarası"
              name="identityNumber"
              fullWidth
              value={buyer.identityNumber}
              onChange={(e) => handleChange(e, setBuyer)}
              inputProps={{ maxLength: 11 }} // TC Kimlik numarası 11 haneli
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="E-posta"
              name="email"
              type="email"
              fullWidth
              value={buyer.email}
              onChange={(e) => handleChange(e, setBuyer)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Telefon"
              name="gsmNumber"
              type="tel"
              fullWidth
              value={buyer.gsmNumber}
              onChange={(e) => handleChange(e, setBuyer)}
            />
          </Grid>
          {/* Diğer alıcı bilgileri için alanlar */}
          {/* Örneğin: adres, şehir, ülke vb. */}
        </Grid>

        {/* Gönderim Adresi - Sadece Tek Seferlik Ödemelerde Göster */}
        {type === 'onetime' && (
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Gönderim Adresi
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Adres"
                  name="address"
                  fullWidth
                  value={shippingAddress.address}
                  onChange={(e) => handleChange(e, setShippingAddress)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Şehir"
                  name="city"
                  fullWidth
                  value={shippingAddress.city}
                  onChange={(e) => handleChange(e, setShippingAddress)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Ülke"
                  name="country"
                  fullWidth
                  value={shippingAddress.country}
                  onChange={(e) => handleChange(e, setShippingAddress)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Posta Kodu"
                  name="zipCode"
                  fullWidth
                  value={shippingAddress.zipCode}
                  onChange={(e) => handleChange(e, setShippingAddress)}
                />
              </Grid>
            </Grid>
          </Box>
        )}

        {/* Fatura Adresi */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            Fatura Adresi
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                label="Adres"
                name="address"
                fullWidth
                value={billingAddress.address}
                onChange={(e) => handleChange(e, setBillingAddress)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Şehir"
                name="city"
                fullWidth
                value={billingAddress.city}
                onChange={(e) => handleChange(e, setBillingAddress)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Ülke"
                name="country"
                fullWidth
                value={billingAddress.country}
                onChange={(e) => handleChange(e, setBillingAddress)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Posta Kodu"
                name="zipCode"
                fullWidth
                value={billingAddress.zipCode}
                onChange={(e) => handleChange(e, setBillingAddress)}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Sepet Özeti */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            Sepet Özeti
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Ürün Adı</TableCell>
                  <TableCell align="right">SKU</TableCell>
                  <TableCell align="right">Adet</TableCell>
                  <TableCell align="right">Birim Fiyat</TableCell>
                  <TableCell align="right">Toplam</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart.map((item) => (
                  <TableRow key={item.sku}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">{item.sku}</TableCell>
                    <TableCell align="right">{item.quantity}</TableCell>
                    <TableCell align="right">
                      ₺
                      {Array.isArray(item.prices)
                        ? item.prices.find((p) => p.currencyType === 'try')?.value.toFixed(2) || '0.00'
                        : item.prices?.value?.toFixed(2) || '0.00'}
                    </TableCell>
                    <TableCell align="right">
                      ₺{(
                        (Array.isArray(item.prices)
                          ? item.prices.find((p) => p.currencyType === 'try')?.value || 0
                          : item.prices?.value || 0) * (item.quantity || 1)
                      ).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Typography variant="h6">
              Toplam: ₺
              {calculateTotalPrice()}
            </Typography>
          </Box>
        </Box>

        <Box mt={4} textAlign="center">
          <Button type="submit" variant="contained" color="primary" size="large">
            Ödemeyi Tamamla
          </Button>
        </Box>
      </form>
    </Container>
  );
}
