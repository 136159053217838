import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, Box, Typography } from '@mui/material';
import { CheckCircle, Error } from '@mui/icons-material'; // İkonlar
import { deleteOnetimeCartItems,deleteSubscriptionCartItems } from '../helpers/localStorageCart';

const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center" as "center",
    padding: "20px",
  },
  icon: (status: string) => ({
    fontSize: "80px",
    color: status === 'success' ? 'green' : 'red', // Başarılıysa yeşil, başarısızsa kırmızı
  }),
  message: (status: string) => ({
    marginTop: "20px",
    color: status === 'success' ? 'green' : 'red',
  }),
  countdown: {
    marginTop: "10px",
    fontWeight: "bold" as "bold",
  },
};

function PaymentCallback() {
  const { paymentType, status } = useParams(); // URL'deki :paymentType ve :status parametrelerini oku
  const navigate = useNavigate();

  const [countdown, setCountdown] = useState<number>(5);

  useEffect(() => {
    if (status === 'success') {
      console.log(`${paymentType} ödeme başarılı!`);
      
      if (paymentType === 'onetime') {
        deleteOnetimeCartItems();
      } else if (paymentType === 'subscription') {
        deleteSubscriptionCartItems();
      }
    } else {
      console.log(`${paymentType} ödeme başarısız!`);
      // Başarısızlık durumunda yapılacak işlemler
    }

    // Geri sayım başlat
    const timer = setInterval(() => {
      setCountdown(prev => prev - 1);
    }, 1000);

    // 5 saniye sonra yönlendirme yap
    const redirectTimer = setTimeout(() => {
      navigate('/dashboard', { replace: true });
    }, 5000);

    // Temizlik işlemi
    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [paymentType, status, navigate]);

  return (
    <Box sx={styles.container}>
      {status === 'success' ? (
        <CheckCircle sx={styles.icon('success')} /> // Yeşil tik
      ) : (
        <Error sx={styles.icon('failure')} /> // Kırmızı çarpı
      )}
      <Typography variant="h5" sx={styles.message(status || '')}>
        {status === 'success' ? 'Ödeme işlemi başarılı oldu!' : 'Ödeme işlemi başarısız oldu!'}
      </Typography>
      <Typography variant="body1">
        Lütfen bekleyiniz.
      </Typography>
      <Typography variant="body2" sx={styles.countdown}>
        Yönlendiriliyorsunuz... {countdown} saniye
      </Typography>
    </Box>
  );
}

export default PaymentCallback;
