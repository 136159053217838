// src/helpers/localStorageCart.js

// LocalStorage'dan sepeti alma
export const getCartFromLocalStorage = () => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    return cart;
  };
  
  export const addToCart = (product) => {
    const cart = getCartFromLocalStorage();
  
    // Eğer ürün bir bundle ise sepetteki diğer bundle'ları kaldır
    console.log(product);
    if (product.prices.paymentPeriod !== "onetime") {
        const nonBundleItems = cart.filter(
          (item) => item.prices.paymentPeriod === "onetime" // Sadece "onetime" olan ürünleri filtrele
        );
        cart.length = 0; // Sepeti temizle
        cart.push(...nonBundleItems); // Sadece "onetime" olanları geri ekle
      }
      
  
    // Hem `sku` hem `offerId` kontrolü
    const existingItem = cart.find((item) => item.sku === product.sku && item.offerId === product.offerId);
  
    if (existingItem) {
      // Eğer ürün zaten sepette varsa, miktarı artır
      existingItem.quantity += 1;
    } else {
      // Ürünü fiyat bilgisiyle birlikte sepete ekle
      cart.push({
        ...product,
        quantity: 1,
        price: product.price, // Sepete fiyat bilgisini ekliyoruz
      });
    }
  
    localStorage.setItem('cart', JSON.stringify(cart));
    return cart;
  };
  
  // Sepetten bir ürünü birer birer çıkarma
  export const removeOneItem = (sku) => {
    const cart = getCartFromLocalStorage();
    const existingItem = cart.find((item) => item.sku === sku);
    if (existingItem) {
      existingItem.quantity -= 1; // Miktarı azalt
      if (existingItem.quantity <= 0) {
        // Eğer miktar sıfırsa ürünü tamamen kaldır
        return removeFromCart(sku);
      }
    }
    localStorage.setItem('cart', JSON.stringify(cart));
    return cart;
  };
  
  // Sepetten bir ürünü tamamen çıkarma
  export const removeFromCart = (sku) => {
    const cart = getCartFromLocalStorage();
    const updatedCart = cart.filter((item) => item.sku !== sku);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    return updatedCart;
  };
  
  // Sepeti tamamen temizleme
  export const clearCart = () => {
    localStorage.removeItem('cart');
    return [];
  };
  

export const deleteOnetimeCartItems = () => {
    const cart = getCartFromLocalStorage();
    const updatedCart = cart.filter(item => item.prices.paymentPeriod !== 'onetime');
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    console.log("Onetime sepet öğeleri başarıyla silindi.");
};

export const deleteSubscriptionCartItems = () => {
    const cart = getCartFromLocalStorage();
    const updatedCart = cart.filter(item => 
      item.prices.paymentPeriod !== 'monthly' && 
      item.prices.paymentPeriod !== 'yearly'
    );
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    console.log("Subscription sepet öğeleri başarıyla silindi.");
};
