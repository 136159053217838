import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import Cards from './pages/Cards';
import ProtectedRoute from './components/ProtectedRoute';
import Analytics from './pages/Analytics';
import ReportDetail from './pages/ReportDetail';
import Sales from './pages/Sales';
import Settings from './pages/Settings';
import About from './pages/About';
import Feedback from './pages/Feedback';
import Cart from './pages/Cart';
import Profile from './pages/Profile';
import Dashboard from './pages/Dashboard';
import Payment from './pages/Payment';
import PaymentCallback from './pages/PaymentCallback';
import Landing from './Landing';
import TestPage from './pages/TestPage';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    
      <Routes>
        <Route path="/" element={<SignUp />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/login" element={<SignIn />} />

        {/* Routes inside Layout */}
        <Route element={<Layout />}>
          {/* Protected routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/test" element={<TestPage />} /> {/* Yeni Test Page rotası */}

          <Route
            path="/cards"
            element={
              <ProtectedRoute>
                <Cards />
              </ProtectedRoute>
            }
          />
          {/* Additional protected pages */}
          <Route
            path="/analytics"
            element={
              <ProtectedRoute>
                <Analytics />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reportdetail/:reportId"
            element={
              <ProtectedRoute>
                <ReportDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sales"
            element={
              <ProtectedRoute>
                <Sales />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cart"
            element={
              <ProtectedRoute>
                <Cart />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment"
            element={
              <ProtectedRoute>
                <Payment />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment-callback/:paymentType/:status"
            element={
              <ProtectedRoute>
                <PaymentCallback />
              </ProtectedRoute>
            }
          />       
          <Route
            path="/about"
            element={
              <ProtectedRoute>
                <About />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/feedback"
            element={
              <ProtectedRoute>
                <Feedback />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
  );
}

export default App;
