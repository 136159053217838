// src/pages/Cart.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { addToCart, removeOneItem, removeFromCart, getCartFromLocalStorage, clearCart } from '../helpers/localStorageCart';

export default function Cart() {
  const [cart, setCart] = useState([]);
  const navigate = useNavigate();

  // Sepet verisini localStorage'dan yükleme
  useEffect(() => {
    setCart(getCartFromLocalStorage());
  }, []);

  // Sepeti abonelik ve tek seferlik ürünler olarak ayırma
  const subscriptionItems = cart.filter(item => item.prices.paymentPeriod === 'monthly' || item.prices.paymentPeriod === 'yearly');
  const oneTimeItems = cart.filter(item => item.prices.paymentPeriod === 'onetime');

  // Toplam fiyat hesaplama
  const calculateTotalPrice = (items) => {
    return items
      .reduce((total, item) => {
        const price = Array.isArray(item.prices)
          ? item.prices.find((p) => p.currencyType === 'try')?.value || 0
          : item.prices?.value || 0;
        return total + price * (item.quantity || 1);
      }, 0)
      .toFixed(2);
  };

  // Ödeme sayfasına yönlendirme fonksiyonu
  const handleProceedToPayment = (type) => {
    const itemsToSend = type === 'subscription' ? subscriptionItems : oneTimeItems;
    navigate('/payment', { state: { cart: itemsToSend, type } });
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" fontWeight="bold" mb={3}>
        Sepetiniz
      </Typography>

      {cart.length === 0 ? (
        <Typography variant="body1" color="textSecondary" align="center" mt={4}>
          Sepetiniz boş. Ürün eklemek için geri dönün!
        </Typography>
      ) : (
        <>
          {/* Abonelik Ürünleri */}
          {subscriptionItems.length > 0 && (
            <Box mb={4}>
              <Typography variant="h5" fontWeight="bold" mb={2}>
                Abonelik Ürünleri
              </Typography>
              <List>
                {subscriptionItems.map((item) => (
                  <ListItem
                    key={item.sku}
                    divider
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ListItemText
                      primary={`${item.name} ${
                        item.prices.paymentPeriod === 'monthly' ? 'Aylık' : 'Yıllık'
                      }`}
                      secondary={`Adet: ${item.quantity} x Birim Fiyat: ₺${
                        Array.isArray(item.prices)
                          ? item.prices.find((p) => p.currencyType === 'try')?.value || '0.00'
                          : item.prices?.value || '0.00'
                      }`}
                    />

                    <Box display="flex" alignItems="center" gap={1}>
                      <IconButton
                        size="small"
                        onClick={() => setCart(removeOneItem(item.sku))}
                        color="secondary"
                      >
                        <RemoveIcon fontSize="small" />
                      </IconButton>
                      <Typography variant="body2">{item.quantity || 0}</Typography>
                      <IconButton
                        size="small"
                        onClick={() => setCart(addToCart(item))}
                        color="primary"
                      >
                        <AddIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => setCart(removeFromCart(item.sku))}
                        color="error"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </ListItem>
                ))}
              </List>

              {/* Abonelik Toplam Fiyat ve Ödeme Butonu */}
              <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                <Typography variant="h6" fontWeight="bold">
                  Abonelik Toplam Fiyat: ₺{calculateTotalPrice(subscriptionItems)}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleProceedToPayment('subscription')}
                >
                  Abonelik Ödemesine Git
                </Button>
              </Box>
            </Box>
          )}

          {/* Tek Seferlik Ürünler */}
          {oneTimeItems.length > 0 && (
            <Box mb={4}>
              <Typography variant="h5" fontWeight="bold" mb={2}>
                Tek Seferlik Ürünler
              </Typography>
              <List>
                {oneTimeItems.map((item) => (
                  <ListItem
                    key={item.sku}
                    divider
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ListItemText
                      primary={item.name}
                      secondary={`Adet: ${item.quantity} x Birim Fiyat: ₺${
                        Array.isArray(item.prices)
                          ? item.prices.find((p) => p.currencyType === 'try')?.value || '0.00'
                          : item.prices?.value || '0.00'
                      }`}
                    />

                    <Box display="flex" alignItems="center" gap={1}>
                      <IconButton
                        size="small"
                        onClick={() => setCart(removeOneItem(item.sku))}
                        color="secondary"
                      >
                        <RemoveIcon fontSize="small" />
                      </IconButton>
                      <Typography variant="body2">{item.quantity || 0}</Typography>
                      <IconButton
                        size="small"
                        onClick={() => setCart(addToCart(item))}
                        color="primary"
                      >
                        <AddIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => setCart(removeFromCart(item.sku))}
                        color="error"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </ListItem>
                ))}
              </List>

              {/* Tek Seferlik Toplam Fiyat ve Ödeme Butonu */}
              <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                <Typography variant="h6" fontWeight="bold">
                  Tek Seferlik Toplam Fiyat: ₺{calculateTotalPrice(oneTimeItems)}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleProceedToPayment('onetime')}
                >
                  Tek Seferlik Ödemeye Git
                </Button>
              </Box>
            </Box>
          )}

          {/* Sepeti Boşaltma Butonu */}
          <Box textAlign="center" mt={4}>
            <Button variant="outlined" color="error" onClick={() => setCart(clearCart())}>
              Sepeti Boşalt
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
}
