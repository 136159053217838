import { fetchWithAuth } from '../api/fetchWrapper';

// Veriyi API formatına dönüştürme fonksiyonu
// transformPaymentData fonksiyonu
interface InputData {
    items?: Array<{
        name?: string;
        prices?: { value?: number };
        category1?: string;
        category2?: string;
        itemType?: string;
        quantity?: number;
    }>;
    paidPrice?: string;
    buyer?: {
        id?: string;
        name?: string;
        surname?: string;
        identityNumber?: string;
        email?: string;
        gsmNumber?: string;
        registrationDate?: string;
        lastLoginDate?: string;
        registrationAddress?: string;
        city?: string;
        country?: string;
        zipCode?: string;
        ip?: string;
    };
    shippingAddress?: {
        address?: string;
        zipCode?: string;
        contactName?: string;
        city?: string;
        country?: string;
    };
    billingAddress?: {
        address?: string;
        zipCode?: string;
        contactName?: string;
        city?: string;
        country?: string;
    };
    paymentType: string;
    pricingPlanReferenceCode?: string;
    subscriptionInitialStatus?: string;
}

interface TransformedData {
    locale: string;
    conversationId: string;
    price: string;
    basketId: string;
    paymentGroup: string;
    callbackUrl: string;
    currency: string;
    paidPrice: string;
    buyer?: {
        id: string;
        name: string;
        surname: string;
        identityNumber: string;
        email: string;
        gsmNumber: string;
        registrationDate: string;
        lastLoginDate: string;
        registrationAddress: string;
        city: string;
        country: string;
        zipCode: string;
        ip: string;
    };
    shippingAddress?: {
        address: string;
        zipCode: string;
        contactName: string;
        city: string;
        country: string;
    };
    billingAddress?: {
        address: string;
        zipCode: string;
        contactName: string;
        city: string;
        country: string;
    };
    basketItems?: Array<{
        id: string;
        price: string;
        name: string;
        category1: string;
        category2: string;
        itemType: string;
    }>;
    customer?: {
        name: string;
        surname: string;
        email: string;
        gsmNumber: string;
        identityNumber: string;
        billingAddress: {
            address: string;
            zipCode: string;
            contactName: string;
            city: string;
            country: string;
        };
    };
}

function transformPaymentData(inputData: InputData) {
    // Varsayılan değerler (İhtiyaca göre dinamik hale getirilebilir)
    const basketId = "B67832";
    const paymentGroup = "PRODUCT";
    const callbackUrl = "https://identity.feedup.ai/iyzipay_api/callback/onetime";
    const conversationId = "123456789";
    const locale = "tr";
    const currency = "TRY";

    if (inputData.paymentType === 'subscription') {
        return {
            callbackUrl: "https://identity.feedup.ai/iyzipay_api/callback/subscription",
            subscriptionInitialStatus: "ACTIVE",
            pricingPlanReferenceCode: inputData.items[0]["prices"]["offerId"],
            customer: {
                name: inputData.buyer?.name || "",
                surname: inputData.buyer?.surname || "",
                email: inputData.buyer?.email || "",
                gsmNumber: inputData.buyer?.gsmNumber || "",
                identityNumber: inputData.buyer?.identityNumber || "",
                billingAddress: {
                    address: inputData.billingAddress?.address || "",
                    zipCode: inputData.billingAddress?.zipCode || "",
                    contactName: inputData.buyer?.name + " " + inputData.buyer?.surname || "",
                    city: inputData.billingAddress?.city || "",
                    country: inputData.billingAddress?.country || "",
                },
            },
        };
    }

    // Sepet öğelerini dönüştürme ve fiyatları hesaplama
    let basketItems: TransformedData["basketItems"] = [];

    inputData.items?.forEach((item, index) => {
        console.log(`Item ${index + 1}:`, item);
        console.log(`Item ${index + 1} Prices:`, item.prices?.value);

        // 'prices.total' alanının varlığını kontrol edin
        const price = item.prices?.value;
        if (!price) {
            console.warn(`Item ${index + 1} için 'prices.total' alanı bulunamadı. Varsayılan fiyat kullanılıyor.`);
        }

        // Quantity değerini al, yoksa 1 olarak varsay
        const quantity = item.quantity && Number.isInteger(item.quantity) && item.quantity > 0 ? item.quantity : 1;

        for (let i = 0; i < quantity; i++) {
            basketItems.push({
                id: `BI${basketItems.length + 1}`, // Örneğin, BI1, BI2, vb.
                price: price ? price.toString() : "0.0", // 'prices.total' yoksa "0.0" kullan
                name: item.name || "Product",
                category1: item.category1 || "DefaultCategory1", // İhtiyaca göre doldurun
                category2: item.category2 || "DefaultCategory2", // İhtiyaca göre doldurun
                itemType: item.itemType || "PHYSICAL" // Varsayılan değer
            });
        }
    });

    console.log("Dönüştürülmüş basketItems:", basketItems);

    // Toplam fiyatı hesapla
    const totalPrice = basketItems.reduce((total, item) => {
        const itemPrice = parseFloat(item.price);
        console.log(item);
        if (isNaN(itemPrice)) {
            console.warn(`Item ${item.id} için geçersiz fiyat: ${item.price}. 0 olarak kabul ediliyor.`);
            return total;
        }
        return total + itemPrice;
    }, 0).toFixed(2);

    console.log("Toplam fiyat:", totalPrice);

    // paidPrice'i ayarla (genellikle totalPrice ile aynı)
    const paidPrice = inputData.paidPrice || totalPrice;

    console.log("paidPrice:", paidPrice);

    // Buyer alanını doldur
    const buyer = {
        id: inputData.buyer?.id || "BY123",
        name: inputData.buyer?.name || "Ali",
        surname: inputData.buyer?.surname || "Veli",
        identityNumber: inputData.buyer?.identityNumber || "12345678901",
        email: inputData.buyer?.email || "ali.veli@example.com",
        gsmNumber: inputData.buyer?.gsmNumber || "+905555555555",
        registrationDate: inputData.buyer?.registrationDate || "2023-01-01 12:00:00",
        lastLoginDate: inputData.buyer?.lastLoginDate || "2023-12-31 12:00:00",
        registrationAddress: inputData.buyer?.registrationAddress || "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
        city: inputData.buyer?.city || "Istanbul",
        country: inputData.buyer?.country || "Turkey",
        zipCode: inputData.buyer?.zipCode || "34732",
        ip: inputData.buyer?.ip || "192.168.1.1"
    };

    // Shipping address'i doldur
    const shippingAddress = {
        address: inputData.shippingAddress?.address || "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
        zipCode: inputData.shippingAddress?.zipCode || "34742",
        contactName: inputData.shippingAddress?.contactName || "Ali Veli",
        city: inputData.shippingAddress?.city || "Istanbul",
        country: inputData.shippingAddress?.country || "Turkey"
    };

    // Billing address'i doldur
    const billingAddress = {
        address: inputData.billingAddress?.address || "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
        zipCode: inputData.billingAddress?.zipCode || "34742",
        contactName: inputData.billingAddress?.contactName || "Ali Veli",
        city: inputData.billingAddress?.city || "Istanbul",
        country: inputData.billingAddress?.country || "Turkey"
    };

    return {
        locale: locale,
        conversationId: conversationId,
        price: totalPrice, // String olarak
        basketId: basketId,
        paymentGroup: paymentGroup,
        callbackUrl: callbackUrl,
        currency: currency,
        paidPrice: paidPrice.toString(), // String olarak
        buyer: buyer,
        shippingAddress: inputData.paymentType === 'onetime' ? shippingAddress : undefined,
        billingAddress: billingAddress,
        basketItems: basketItems
    };
}




export async function sendPaymentData(paymentData) {
    try {
        console.log("Orijinal Payment Data:", paymentData);

        // Veriyi dönüştür
        const transformedData = transformPaymentData(paymentData);

        console.log("Dönüştürülmüş Payment Data:", transformedData);

        const paymentType = paymentData.paymentType;

        // Endpoint'i paymentType'a göre seç
        const endpoint = paymentType === 'onetime'
            ? '/iyzipay_api/checkout/single-payment'
            : '/iyzipay_api/checkout/subscription';

        const response = await fetchWithAuth(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(transformedData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('API Hata Detayları:', errorData);
            throw new Error(JSON.stringify(errorData));
        }

        const data = await response.json();
        console.log(data);

        if (paymentType === 'subscription' && data.checkoutFormContent) {
            // Gelen script'i göstermek için bir container oluştur
            const container = document.createElement('div');
            container.setAttribute('id', 'checkout-form-container');
            container.style.position = 'fixed';
            container.style.top = '0';
            container.style.left = '0';
            container.style.width = '100%';
            container.style.height = '100%';
            container.style.backgroundColor = 'rgba(255, 255, 255, 0.9)';
            container.style.zIndex = '9999';
            container.style.overflow = 'auto';

            // Gelen içerik div'e ekleniyor
            container.innerHTML = data.checkoutFormContent;
            document.body.appendChild(container);

            // Gelen içeriğin içindeki script'leri çalıştır
            const scripts = container.getElementsByTagName('script');
            for (let script of Array.from(scripts)) {
                const newScript = document.createElement('script');
                newScript.textContent = script.textContent;
                document.body.appendChild(newScript);
                document.body.removeChild(newScript); // Çalıştırıldıktan sonra kaldır
            }

            // ESC ile formu kaldırma
            const removeCheckoutForm = (e) => {
                if (e.key === 'Escape') {
                    document.body.removeChild(container);
                    window.removeEventListener('keydown', removeCheckoutForm);
                }
            };
            window.addEventListener('keydown', removeCheckoutForm);
        }

        return data;
    } catch (error) {
        console.error('sendPaymentData Hatası:', error);
        throw error;
    }
}
